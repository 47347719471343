/* data table related */
import { __ } from '@wordpress/i18n';
import { FilterMatchMode } from 'primereact/api';

const currentPageReportTemplate = '';

const emptyMessage = __('Nessun dato disponibile', 'gepafin');

const selectOneLabel = __('Seleziona', 'gepafin');

const numberFilterOptions = [
    { label: 'Uguale a', value: FilterMatchMode.EQUALS }
];

const textFilterOptions = [
    //{ label: 'Inizia con', value: FilterMatchMode.STARTS_WITH },
    { label: 'Contiene', value: FilterMatchMode.CONTAINS },
    //{ label: 'Non contiene', value: FilterMatchMode.NOT_CONTAINS },
    //{ label: 'Finisce con', value: FilterMatchMode.ENDS_WITH },
    { label: 'Uguale a', value: FilterMatchMode.EQUALS },
    //{ label: 'Diverso da', value: FilterMatchMode.NOT_EQUALS }
];

const statusFilterOptions = [
    { label: 'Uguale a', value: FilterMatchMode.EQUALS }
];

const numericFilterOptions = [
    { label: 'Uguale a', value: FilterMatchMode.EQUALS },
    { label: 'Diverso da', value: FilterMatchMode.NOT_EQUALS },
    { label: 'Maggiore di', value: FilterMatchMode.GREATER_THAN },
    { label: 'Maggiore o uguale a', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
    { label: 'Minore di', value: FilterMatchMode.LESS_THAN },
    { label: 'Minore o uguale a', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO }
];

const dateFilterOptions = [
    { label: 'Data uguale a', value: FilterMatchMode.DATE_IS },
    { label: 'Data diversa da', value: FilterMatchMode.DATE_IS_NOT },
    { label: 'Data prima di', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Data dopo', value: FilterMatchMode.DATE_AFTER }
];

const translationStrings = {
    currentPageReportTemplate,
    emptyMessage,
    selectOneLabel,
    textFilterOptions,
    numberFilterOptions,
    statusFilterOptions,
    numericFilterOptions,
    dateFilterOptions
}

export default translationStrings;