import React from 'react';
import { __ } from '@wordpress/i18n';
import { head } from 'ramda';

// store
import { useStore } from '../../store';

// components
import AllDomandeBeneficiarioTableAsync from './components/AllDomandeBeneficiarioTableAsync';

const DomandeBeneficiario = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const companies = useStore().main.companies();
    const company = head(companies.filter(o => o.id === chosenCompanyId));

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Archivio domande', 'gepafin')}</h1>
                {company ? <span className="companyName">{company.companyName}</span> : null}
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <AllDomandeBeneficiarioTableAsync statuses={['SOCCORSO', 'APPROVED', 'REJECTED', 'EVALUATION', 'SUBMIT',
                    'APPOINTMENT', 'NDG', 'ADMISSIBLE', 'TECHNICAL_EVALUATION']}/>
            </div>
        </div>
    )
}

export default DomandeBeneficiario;