import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { is } from 'ramda';
import { Link, useLocation } from 'react-router-dom';

import translationStrings from '../../../../translationStringsForComponents';

// api
import ApplicationService from '../../../../service/application-service';

//
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';
import getFormattedDateString from '../../../../helpers/getFormattedDateString';
import getQueryParamsForPaginatedEndpoint from '../../../../helpers/getQueryParamsForPaginatedEndpoint';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';

const AllDomandeTableAsync = ({ openDialogFn, updaterString = '' }) => {
    const location = useLocation();
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [totalRecordsNum, setTotalRecordsNum] = useState(0);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 5,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            id: { value: null, matchMode: 'equals' },
            callTitle: { value: null, matchMode: 'contains' },
            companyName: { value: null, matchMode: 'contains' },
            submissionDate: { value: null, matchMode: 'date_is' },
            assignedUserName: { value: null, matchMode: 'equals' },
            status: { value: null, matchMode: 'equals' }
        }
    });
    const statuses = ['SUBMIT', 'EVALUATION', 'SOCCORSO', 'APPOINTMENT', 'NDG', 'ADMISSIBLE', 'TECHNICAL_EVALUATION'];

    const getPaginationQuery = useCallback(() => getQueryParamsForPaginatedEndpoint(lazyState, statuses, 'id'), [lazyState]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    };

    const onFilter = useCallback((event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    }, [lazyState]);

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const { body, totalRecords,
                //currentPage, totalPages, pageSize
            } = resp.data;
            setTotalRecordsNum(totalRecords);
            setItems(getFormattedData(body));
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.callEndDate = is(String, d.callEndDate) ? new Date(d.callEndDate) : (d.callEndDate ? d.callEndDate : '');
            d.modifiedDate = is(String, d.modifiedDate) ? new Date(d.modifiedDate) : (d.modifiedDate ? d.modifiedDate : '');
            d.submissionDate = is(String, d.submissionDate) ? new Date(d.submissionDate) : (d.submissionDate ? d.submissionDate : '');
            return d;
        });
    };

    const actionsBodyTemplate = (rowData) => {
        return <div className="appPageSection__tableActions lessGap">
            {openDialogFn && ['SUBMIT', 'EVALUATION', 'SOCCORSO'].includes(rowData.status)
                ? <Button severity="info"
                          onClick={() => openDialogFn(rowData.id)}
                          label={__('Assegnare', 'gepafin')}
                          icon="pi pi-pencil" size="small" iconPos="right"/>
                : location.pathname !== '/domande'
                    ? <Link to={'/domande'}>
                        <Button severity="info" label={__('Gestire', 'gepafin')} size="small"/>
                    </Link> : null}
            <Link to={`/domande/${rowData.id}/preview`}>
                <Button severity="info" label={__('Anteprima', 'gepafin')} icon="pi pi-eye" size="small"
                        iconPos="right"/>
            </Link>
        </div>
    }

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => {
                             options.filterCallback(e.value, options.index)
                             const filters = { ...lazyState.filters };
                             if (e.value) {
                                 filters['status'] = { value: e.value, matchMode: 'equals' };
                             } else {
                                 delete filters['status'];
                             }
                             setLazyState({ ...lazyState, filters, first: 0 });
                         }}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter"
                         showClear/>;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999"/>;
    };

    const dateAppliedBodyTemplate = (rowData) => {
        return getFormattedDateString(rowData.submissionDate);
    };

    useEffect(() => {
        setLocalAsyncRequest(true);
        const paginationQuery = getPaginationQuery();

        ApplicationService.getApplicationsPaginated(paginationQuery, getCallback, errGetCallbacks);
    }, [lazyState, updaterString]);

    return (
        <div className="appPageSection__table">
            <DataTable
                value={items} stripedRows showGridlines
                lazy filterDisplay="menu" dataKey="id" paginator
                first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecordsNum} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={localAsyncRequest}
                emptyMessage={translationStrings.emptyMessage}>
                <Column field="id" header={__('ID domanda', 'gepafin')}
                        sortable
                        filterField="id" filter
                        filterMatchModeOptions={translationStrings.numberFilterOptions}
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="protocolNumber" header={__('Protocollo', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="callTitle" header={__('Bando', 'gepafin')}
                        filterField="callTitle" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '10rem' }}/>
                <Column field="companyName" header={__('Azienda Beneficiaria', 'gepafin')}
                        filterField="companyName" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data Ricezione', 'gepafin')}
                        filterElement={dateFilterTemplate} filter
                        filterField="submissionDate" dataType="date"
                        filterMatchModeOptions={translationStrings.dateFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={dateAppliedBodyTemplate}/>
                <Column field="assignedUserName" header={__('Assegnato', 'gepafin')}
                        filterField="assignedUserName" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        style={{ minWidth: '8rem' }}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        filterElement={statusFilterTemplate} filter
                        filterMatchModeOptions={translationStrings.statusFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={statusBodyTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default AllDomandeTableAsync;