import React from 'react';
import { __ } from '@wordpress/i18n';

// components
import DomandeTablePreInstructorAsync from '../DashboardPreInstructor/components/DomandeTablePreInstructorAsync';

const DomandeArchive = () => {
    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Archivio domande', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Domande completate', 'gepafin')}</h2>
                <DomandeTablePreInstructorAsync statuses={['CLOSE']} userId={0}/>
            </div>
        </div>
    )
}

export default DomandeArchive;