import { isEmpty, pathOr } from 'ramda';
import formatDateString from './formatDateString';

const getQueryParamsForPaginatedEndpoint = (lazyState, statuses, sortByCol = 'applicationId') => {
    let sortBy = {
        columnName: sortByCol,
        sortDesc: true
    };

    if (lazyState.sortField) {
        sortBy = {
            columnName: lazyState.sortField,
            sortDesc: lazyState.sortOrder === -1
        }
    }

    return {
        globalFilters: {
            page: lazyState.page ? lazyState.page + 1 : 1,
            limit: lazyState.rows,
            sortBy
        },
        status: statuses,
        filters: Object.keys(lazyState.filters).reduce((acc, cur) => {
            const value = pathOr('', ['filters', cur, 'value'], lazyState);
            const matchMode = pathOr('', ['filters', cur, 'matchMode'], lazyState);
            if (!isEmpty(value)) {
                acc[cur] = typeof value.getMonth === 'function'
                    ? {value: formatDateString(value), matchMode}
                    : lazyState.filters[cur];
            }
            return acc;
        }, {}),
    }
}

export default getQueryParamsForPaginatedEndpoint;