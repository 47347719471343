import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { head, is } from 'ramda';
import { klona } from 'klona';

import translationStrings from '../../../../translationStringsForComponents';

// api
import AssignedApplicationService from '../../../../service/assigned-application-service';

// store
import { useStore } from '../../../../store';

// tools
import getFormattedDateString from '../../../../helpers/getFormattedDateString';
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import getQueryParamsForPaginatedEndpoint from '../../../../helpers/getQueryParamsForPaginatedEndpoint';


const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const MieDomandeTableInstructorManagerAsync = ({ userId = null, statuses = [] }) => {
    const navigate = useNavigate();
    const userData = useStore().main.userData();
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [totalRecordsNum, setTotalRecordsNum] = useState(0);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 5,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            applicationId: { value: null, matchMode: 'contains' },
            protocolNumber: { value: null, matchMode: 'contains' },
            callName: { value: null, matchMode: 'contains' },
            companyName: { value: null, matchMode: 'contains' },
            submissionDate: { value: null, matchMode: 'date_is' },
            evaluationEndDate: { value: null, matchMode: 'date_is' },
            applicationStatus: { value: null, matchMode: 'equals' }
        }
    });

    const getPaginationQuery = useCallback(() => getQueryParamsForPaginatedEndpoint(lazyState, statuses, 'applicationId'), [lazyState]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    };

    const onFilter = useCallback((event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    }, [lazyState]);

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const { body, totalRecords,
                //currentPage, totalPages, pageSize
            } = resp.data;
            setTotalRecordsNum(totalRecords);
            setItems(getFormattedData(body));
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.evaluationEndDate = is(String, d.evaluationEndDate) ? new Date(d.evaluationEndDate) : (d.evaluationEndDate ? d.evaluationEndDate : '');
            d.submissionDate = is(String, d.submissionDate) ? new Date(d.submissionDate) : (d.submissionDate ? d.submissionDate : '');
            return d;
        });
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.applicationStatus}/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => {
                             options.filterCallback(e.value, options.index)
                             const filters = { ...lazyState.filters };
                             if (e.value) {
                                 filters['status'] = { value: e.value, matchMode: 'equals' };
                             } else {
                                 delete filters['status'];
                             }
                             setLazyState({ ...lazyState, filters, first: 0 });
                         }}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter"
                         showClear/>;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999"/>;
    };

    const dateAppliedBodyTemplate = (rowData) => {
        return getFormattedDateString(rowData.submissionDate);
    };

    const dateEndBodyTemplate = (rowData) => {
        return getFormattedDateString(rowData.evaluationEndDate);
    };

    const actionsBodyTemplate = (rowData) => {
        if (rowData.status === 'AWAITING') {
            return <Button
                severity="info"
                onClick={() => handleInitiateEvaluation(rowData.id)}
                label={__('Valuta', 'gepafin')}
                icon="pi pi-eye"
                size="small"
                iconPos="right"/>
        } else {
            const label = ['OPEN', 'SOCCORSO'].includes(rowData.status) && userData.id === rowData.userId
                ? __('Valuta', 'gepafin')
                : __('Mostra', 'gepafin');
            return <Link to={`/mie-domande/${rowData.applicationId}`}>
                <Button severity="info" label={label} icon="pi pi-eye" size="small" iconPos="right"/>
            </Link>
        }
    }

    const handleInitiateEvaluation = (id) => {
        setLocalAsyncRequest(true);
        AssignedApplicationService.updateStatusAssignedApplication(id, getInitEvalCallback, errInitEvalCallbacks, [
            ['status', 'OPEN']
        ])
    }

    const getInitEvalCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const evaluation = klona(head(getFormattedData([resp.data])));
            const newItems = items.map(o => o.id === evaluation.id ? evaluation : o);
            setItems(newItems);
            navigate(`/mie-domande/${evaluation.applicationId}`);
        }
        setLocalAsyncRequest(false);
    }

    const errInitEvalCallbacks = (resp) => {
        setLocalAsyncRequest(false);
    }

    useEffect(() => {
        setLocalAsyncRequest(true);
        const paginationQuery = getPaginationQuery();

        if (userId) {
            AssignedApplicationService.assignApplicationPaginated(paginationQuery, getCallback, errGetCallbacks, [
                ['userId', userId]
            ]);
        } else {
            AssignedApplicationService.assignApplicationPaginated(paginationQuery, getCallback, errGetCallbacks);
        }
    }, [lazyState]);

    return (
        <div className="appPageSection__table">
            <DataTable
                value={items} stripedRows showGridlines
                lazy filterDisplay="menu" dataKey="id" paginator
                first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecordsNum} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={localAsyncRequest}
                emptyMessage={translationStrings.emptyMessage}>
                <Column field="applicationId" header={__('ID domanda', 'gepafin')}
                        sortable
                        filterField="applicationId" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="protocolNumber" header={__('Protocollo', 'gepafin')}
                        sortable
                        filterField="protocolNumber" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                {APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                    ? <Column field="ndg" header={__('NDG', 'gepafin')}
                              sortable filterPlaceholder={__('Cerca', 'gepafin')}
                              style={{ minWidth: '6rem' }}/> : null}
                {APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                    ? <Column field="appointmentId" header={__('ID appuntamento', 'gepafin')}
                              sortable filterPlaceholder={__('Cerca', 'gepafin')}
                              style={{ minWidth: '6rem' }}/> : null}
                <Column field="callName" header={__('Bando', 'gepafin')}
                        sortable
                        filterField="callName" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="companyName" header={__('Azienda', 'gepafin')}
                        sortable
                        filterField="companyName" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data ricezione', 'gepafin')}
                        filterField="submissionDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        filterMatchModeOptions={translationStrings.dateFilterOptions}
                        body={dateAppliedBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Scadenza', 'gepafin')}
                        filterField="evaluationEndDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        filterMatchModeOptions={translationStrings.dateFilterOptions}
                        body={dateEndBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="applicationStatus" header={__('Stato', 'gepafin')}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate}
                        filter
                        filterMatchModeOptions={translationStrings.statusFilterOptions}
                        filterElement={statusFilterTemplate} />
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default MieDomandeTableInstructorManagerAsync;