import React from 'react';
import { __ } from '@wordpress/i18n';

// store
import { useStore } from '../../store';

// components
import MieDomandeTableInstructorManagerAsync from '../DashboardInstructorManager/components/MieDomandeTableInstructorManagerAsync';

const DomandeMieInstructorManager = () => {
    const userData = useStore().main.userData();

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Domande da valutare', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Nuove domande da valutare', 'gepafin')}</h2>
                <MieDomandeTableInstructorManagerAsync statuses={['AWAITING']} userId={userData.id}/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Coda di lavoro', 'gepafin')}</h2>
                <MieDomandeTableInstructorManagerAsync statuses={['OPEN', 'SOCCORSO']} userId={userData.id}/>
            </div>
        </div>
    )
}

export default DomandeMieInstructorManager;