import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { Link } from 'react-router-dom';

import translationStrings from '../../../../translationStringsForComponents';

// api
import BandoService from '../../../../service/bando-service';

// tools
import getTimeParsedFromString from '../../../../helpers/getTimeParsedFromString';
import getTimeFromISOstring from '../../../../helpers/getTimeFromISOstring';
import getFormattedDateString from '../../../../helpers/getFormattedDateString';
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';
import getQueryParamsForPaginatedEndpoint from '../../../../helpers/getQueryParamsForPaginatedEndpoint';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';

const LatestBandiTableAsync = () => {
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [totalRecordsNum, setTotalRecordsNum] = useState(0);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 5,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: null, matchMode: 'contains' },
            startDate: { value: null, matchMode: 'date_is' },
            endDate: { value: null, matchMode: 'date_is' },
            status: { value: null, matchMode: 'equals' }
        }
    });
    const statuses = ['PUBLISH'];

    const getPaginationQuery = useCallback(() => getQueryParamsForPaginatedEndpoint(lazyState, statuses, 'id'), [lazyState]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    };

    const onFilter = useCallback((event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    }, [lazyState]);

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const { body, totalRecords,
                //currentPage, totalPages, pageSize
            } = resp.data;
            setTotalRecordsNum(totalRecords);
            setItems(getFormattedData(body));
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return [...(data || [])].map((d) => {
            d.startDate = new Date(d.dates[0]);
            d.endDate = new Date(d.dates[1]);

            return d;
        });
    };

    const actionsBodyTemplate = (rowData) => {
        return <Link to={`/bandi/${rowData.id}`}>
            <Button severity="info" label={__('Modifica', 'gepafin')} icon="pi pi-pencil" size="small" iconPos="right" />
        </Link>
    }

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => {
                             options.filterCallback(e.value, options.index)
                             const filters = { ...lazyState.filters };
                             if (e.value) {
                                 filters['status'] = { value: e.value, matchMode: 'equals' };
                             } else {
                                 delete filters['status'];
                             }
                             setLazyState({ ...lazyState, filters, first: 0 });
                         }}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter"
                         showClear/>;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999"/>;
    };

    const dateStartBodyTemplate = (rowData) => {
        const startTimeObj = getTimeParsedFromString(rowData.startTime);
        return getFormattedDateString(rowData.startDate) + ' ' + getTimeFromISOstring(startTimeObj);
    };

    const dateEndBodyTemplate = (rowData) => {
        const endTimeObg = getTimeParsedFromString(rowData.endTime);
        return getFormattedDateString(rowData.endDate)  + ' ' + getTimeFromISOstring(endTimeObg);
    };

    useEffect(() => {
        setLocalAsyncRequest(true);
        const paginationQuery = getPaginationQuery();

        BandoService.getBandiPaginated(paginationQuery, getCallback, errGetCallbacks);
    }, [lazyState]);

    return (
        <div className="appPageSection__table">
            <DataTable
                value={items} stripedRows showGridlines
                lazy filterDisplay="menu" dataKey="id" paginator
                first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecordsNum} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={localAsyncRequest}
                emptyMessage={translationStrings.emptyMessage}>
                <Column field="name"
                        sortable
                        filterField="name" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        header={__('Nome Bando', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data Pubblicazione', 'gepafin')}
                        filterElement={dateFilterTemplate} filter
                        filterField="startDate" dataType="date"
                        filterMatchModeOptions={translationStrings.dateFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={dateStartBodyTemplate}/>
                <Column header={__('Data Scadenza', 'gepafin')}
                        filterElement={dateFilterTemplate} filter
                        filterField="endDate" dataType="date"
                        filterMatchModeOptions={translationStrings.dateFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={dateEndBodyTemplate}/>
                <Column field="status"
                        filterElement={statusFilterTemplate} filter
                        filterMatchModeOptions={translationStrings.statusFilterOptions}
                        header={__('Stato', 'gepafin')}
                        style={{ minWidth: '7rem' }}
                        body={statusBodyTemplate} />
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default LatestBandiTableAsync;