import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { is } from 'ramda';
import { Link } from 'react-router-dom';

import translationStrings from '../../../../translationStringsForComponents';

// api
import AmendmentsService from '../../../../service/amendments-service';

//
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';
import getFormattedDateString from '../../../../helpers/getFormattedDateString';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import getQueryParamsForPaginatedEndpoint from '../../../../helpers/getQueryParamsForPaginatedEndpoint';

const SoccorsiInstructorManagerMioTableAsync = ({ userId = null }) => {
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [totalRecordsNum, setTotalRecordsNum] = useState(0);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 5,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            applicationId: { value: null, matchMode: 'contains' },
            callName: { value: null, matchMode: 'contains' },
            companyName: { value: null, matchMode: 'contains' },
            startDate: { value: null, matchMode: 'dateIs' },
            expirationDate: { value: null, matchMode: 'dateIs' },
            status: { value: null, matchMode: 'equals' }
        }
    });
    const statuses = [];

    const getPaginationQuery = useCallback(() => getQueryParamsForPaginatedEndpoint(lazyState, statuses, 'applicationId'), [lazyState]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    };

    const onFilter = useCallback((event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    }, [lazyState]);

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const {
                body, totalRecords,
                //currentPage, totalPages, pageSize
            } = resp.data;
            setTotalRecordsNum(totalRecords);
            setItems(getFormattedData(body));
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.startDate = is(String, d.startDate) ? new Date(d.startDate) : (d.startDate ? d.startDate : '');
            d.expirationDate = is(String, d.expirationDate) ? new Date(d.expirationDate) : (d.expirationDate ? d.expirationDate : '');
            return d;
        });
    };

    const actionsBodyTemplate = (rowData) => {
        return <Link to={`/mie-domande/${rowData.applicationId}/soccorso/${rowData.id}`}>
            <Button severity="info" label={__('Dettagli', 'gepafin')} size="small"/>
        </Link>
    }

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => {
                             options.filterCallback(e.value, options.index)
                             const filters = { ...lazyState.filters };
                             if (e.value) {
                                 filters['status'] = { value: e.value, matchMode: 'equals' };
                             } else {
                                 delete filters['status'];
                             }
                             setLazyState({ ...lazyState, filters, first: 0 });
                         }}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel}
                         className="p-column-filter"
                         showClear/>;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999"/>;
    };

    const dateStartBodyTemplate = (rowData) => {
        return getFormattedDateString(rowData.startDate);
    };

    const dateExpirationBodyTemplate = (rowData) => {
        return rowData.expirationDate ? getFormattedDateString(rowData.expirationDate) : '';
    };

    useEffect(() => {
        setLocalAsyncRequest(true);
        const paginationQuery = getPaginationQuery();

        AmendmentsService.getSoccorsiPaginated(userId, paginationQuery, getCallback, errGetCallbacks);
    }, [lazyState, userId]);

    return (
        <div className="appPageSection__table">
            <DataTable
                value={items} stripedRows showGridlines
                lazy filterDisplay="menu" dataKey="id" paginator
                first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecordsNum} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={localAsyncRequest}
                emptyMessage={translationStrings.emptyMessage}>
                <Column field="applicationId" header={__('ID domanda', 'gepafin')}
                        sortable
                        filterField="applicationId" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="protocolNumber" header={__('Protocollo', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="callName" header={__('Bando', 'gepafin')}
                        filterField="callName" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '10rem' }}/>
                <Column field="companyName" header={__('Azienda Beneficiaria', 'gepafin')}
                        filterField="companyName" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data richiesta', 'gepafin')}
                        filterElement={dateFilterTemplate} filter
                        filterField="startDate" dataType="date"
                        filterMatchModeOptions={translationStrings.dateFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={dateStartBodyTemplate}/>
                <Column header={__('Scadenza', 'gepafin')}
                        filterElement={dateFilterTemplate} filter
                        filterField="expirationDate" dataType="date"
                        filterMatchModeOptions={translationStrings.dateFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={dateExpirationBodyTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        filterElement={statusFilterTemplate} filter
                        filterMatchModeOptions={translationStrings.statusFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={statusBodyTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default SoccorsiInstructorManagerMioTableAsync;