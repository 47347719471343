import React, { useEffect, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { Link, useNavigate } from 'react-router-dom';
import { head, isEmpty, pathOr } from 'ramda';
import NumberFlow from '@number-flow/react';

// store
import { useStore } from '../../store';

// api
import DashboardService from '../../service/dashboard-service';

// components
import { Button } from 'primereact/button';
import ErrorBoundary from '../../components/ErrorBoundary';
import LatestBandiBeneficiarioTableAsync from './components/LatestBandiBeneficiarioTableAsync';
import MyLatestSubmissionsTableAsync from './components/MyLatestSubmissionsTableAsync';

const DashboardBeneficiario = () => {
    const navigate = useNavigate();
    const [mainStats, setMainStats] = useState({});
    const companies = useStore().main.companies();
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const company = head(companies.filter(o => o.id === chosenCompanyId));

    const goToAllSubmissions = () => {
        navigate('/bandi');
    }

    const getStatValue = (key, fallback = '') => {
        return pathOr(fallback, [key], mainStats);
    }

    const getStats = (data) => {
        if (data.status === 'SUCCESS') {
            setMainStats(data.data);
        }
    }

    const errGetStats = () => {
    }

    useEffect(() => {
        const existingCompany = head(companies.filter(o => o.id === chosenCompanyId));

        if (existingCompany) {
            DashboardService.getBeneficiaryStatsForCompany(existingCompany.id, getStats, errGetStats);
        }
    }, [companies, chosenCompanyId]);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Dashboard', 'gepafin')}</h1>
                {company ? <span className="companyName">{company.companyName}</span> : null}
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Panoramica di Sistema', 'gepafin')}</h2>
                <div className="statsBigBadges__grid">
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Domande attive', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfApplications', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Bandi osservati', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfCalls', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Documenti da integrare', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfIntegratedDocuments', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                </div>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Domande in lavorazione', 'gepafin')}</h2>
                <ErrorBoundary><MyLatestSubmissionsTableAsync/></ErrorBoundary>
            </div>

            <div className="appPage__spacer"></div>

            {isEmpty(chosenCompanyId) || chosenCompanyId === 0
                ? <>
                    <div className="appPageSection__message warning">
                        <i className="pi pi-exclamation-triangle"></i>
                        <span className="summary">{__('Attenzione', 'gepafin')}</span>
                        <span>
                            {__('Per applicare ai bandi devi Registare un Azienda clicca', 'gepafin')}
                            <Link to={`/agguingi-azienda`} style={{marginLeft: '0.5ch'}}>{__('qua', 'gepafin')}</Link>
                        </span>
                    </div>
                    <div className="appPage__spacer"></div>
                </> : null}

            <div className="appPageSection">
                <h2>{__('Bandi disponibili', 'gepafin')}</h2>
                <ErrorBoundary><LatestBandiBeneficiarioTableAsync/></ErrorBoundary>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni rapide', 'gepafin')}</span>
            </div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={goToAllSubmissions}
                        label={__('Nuova domanda', 'gepafin')} icon="pi pi-plus" iconPos="right"/>
                    {/*<Button
                        disabled={true}
                        outlined
                        onClick={() => {
                        }}
                        label={__('Carica documento', 'gepafin')} icon="pi pi-upload" iconPos="right"/>
                    <Button
                        disabled={true}
                        outlined
                        onClick={() => {
                        }}
                        label={__('Contatta assistenza', 'gepafin')} icon="pi pi-envelope" iconPos="right"/>*/}
                </div>
            </div>
        </div>
    )
}

export default DashboardBeneficiario;