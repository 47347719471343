import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { is } from 'ramda';
import { Link } from 'react-router-dom';

import translationStrings from '../../../../translationStringsForComponents';

// store
import { useStore } from '../../../../store';

// api
import ApplicationService from '../../../../service/application-service';

//
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';
import getFormattedDateString from '../../../../helpers/getFormattedDateString';
import getQueryParamsForPaginatedEndpoint from '../../../../helpers/getQueryParamsForPaginatedEndpoint';
import isDateTimeInPast from '../../../../helpers/isDateTimeInPast';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { ProgressBar } from 'primereact/progressbar';

const MyLatestSubmissionsTableAsync = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const companies = useStore().main.companies();
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [totalRecordsNum, setTotalRecordsNum] = useState(0);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 5,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            id: { value: null, matchMode: 'equals' },
            callTitle: { value: null, matchMode: 'contains' },
            companyName: { value: null, matchMode: 'contains' },
            submissionDate: { value: null, matchMode: 'date_is' },
            assignedUserName: { value: null, matchMode: 'equals' },
            status: { value: null, matchMode: 'equals' }
        }
    });

    const statuses = ['DRAFT', 'AWAITING', 'READY'];

    const getPaginationQuery = useCallback(() => getQueryParamsForPaginatedEndpoint(lazyState, statuses, 'id'), [lazyState]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    };

    const onFilter = useCallback((event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    }, [lazyState]);

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const { body, totalRecords,
                //currentPage, totalPages, pageSize
            } = resp.data;
            setTotalRecordsNum(totalRecords);
            setItems(getFormattedData(body));
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.callEndDate = is(String, d.callEndDate) ? new Date(d.callEndDate) : (d.callEndDate ? d.callEndDate : '');
            d.modifiedDate = is(String, d.modifiedDate) ? new Date(d.modifiedDate) : (d.modifiedDate ? d.modifiedDate : '');
            return d;
        });
    };

    const confirmDelete = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: __('Sei sicuro di voler rimuovere la domanda?', 'gepafin'),
            acceptLabel: __('Si', 'gepafin'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => {
                handleDeleteApplication(id);
            },
            reject: () => {
            }
        });
    };

    const handleDeleteApplication = (id) => {
        setLocalAsyncRequest(true);
        ApplicationService.deleteApplication(id, (resp) => delApplCallback(resp, id), errDelApplCallback)
    }

    const delApplCallback = (resp, id) => {
        if (resp.status === 'SUCCESS') {
            const newItems = items.filter(o => o.id !== id);
            setItems(newItems);
        }
        setLocalAsyncRequest(false);
    }

    const errDelApplCallback = (data) => {
        setLocalAsyncRequest(false);
    }

    const actionsBodyTemplate = (rowData) => {
        const isCallExpired = isDateTimeInPast(rowData.callEndDate, rowData.callEndTime);
        return <div className="appPageSection__tableActions lessGap">
            {'DRAFT' === rowData.status && !isCallExpired
                ? <Link to={`/imieibandi/${rowData.id}`}>
                    <Button severity="info" label={__('Modifica', 'gepafin')} icon="pi pi-pencil" size="small"
                            iconPos="right"/>
                </Link>
                : null}
            {'DRAFT' !== rowData.status || isCallExpired
                ? <Link to={`/imieibandi/${rowData.id}`}>
                    <Button severity="info" label={__('Mostra', 'gepafin')} icon="pi pi-eye" size="small"
                            iconPos="right"/>
                </Link>
                : null}
            <ConfirmPopup/>
            <Button severity="danger"
                    onClick={(event) => confirmDelete(event, rowData.id)}
                    label={__('Cancella', 'gepafin')}
                    icon="pi pi-trash"
                    size="small"
                    iconPos="right"/>
        </div>
    }

    const progressBodyTemplate = (options) => {
        return <ProgressBar value={options.progress} color={'#64748B'}></ProgressBar>;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => {
                             options.filterCallback(e.value, options.index)
                             const filters = { ...lazyState.filters };
                             if (e.value) {
                                 filters['status'] = { value: e.value, matchMode: 'equals' };
                             } else {
                                 delete filters['status'];
                             }
                             setLazyState({ ...lazyState, filters, first: 0 });
                         }}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter"
                         showClear/>;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999"/>;
    };

    const dateEndBodyTemplate = (rowData) => {
        return getFormattedDateString(rowData.callEndDate);
    };

    const dateModifyBodyTemplate = (rowData) => {
        return getFormattedDateString(rowData.modifiedDate);
    };

    useEffect(() => {
        setLocalAsyncRequest(true);
        const paginationQuery = getPaginationQuery();

        ApplicationService.getApplicationsPaginated(paginationQuery, getCallback, errGetCallbacks, [
            ['companyId', chosenCompanyId]
        ]);
    }, [lazyState, chosenCompanyId, companies]);

    return (
        <div className="appPageSection__table">
            <DataTable
                value={items} stripedRows showGridlines
                lazy filterDisplay="menu" dataKey="id" paginator
                first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecordsNum} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={localAsyncRequest}
                emptyMessage={translationStrings.emptyMessage}>
                <Column field="callTitle" header={__('Bando', 'gepafin')}
                        filterField="callTitle" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '10rem' }}/>
                <Column field="companyName" header={__('Azienda Beneficiaria', 'gepafin')}
                        filterField="companyName" filter
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Scadenza', 'gepafin')}
                        filterField="callEndDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateEndBodyTemplate}
                        filter
                        filterElement={dateFilterTemplate}/>
                <Column header={__('Ultima modifica', 'gepafin')}
                        filterField="modifiedDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateModifyBodyTemplate}
                        filter
                        filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        filterElement={statusFilterTemplate} filter
                        filterMatchModeOptions={translationStrings.statusFilterOptions}
                        style={{ minWidth: '8rem' }}
                        body={statusBodyTemplate}/>
                <Column header={__('Progressi', 'gepafin')}
                        style={{ minWidth: '10rem' }} field="progress" body={progressBodyTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default MyLatestSubmissionsTableAsync;