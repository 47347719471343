import React, { useState, useEffect, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { head, is } from 'ramda';

// store
import { useStore } from '../../../../store';

// api
import ApplicationService from '../../../../service/application-service';

// tools
import getNumberWithCurrency from '../../../../helpers/getNumberWithCurrency';
import getQueryParamsForPaginatedEndpoint from '../../../../helpers/getQueryParamsForPaginatedEndpoint';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import translationStrings from '../../../../translationStringsForComponents';


const BeneficiarioUltimeDomandeTable = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const companies = useStore().main.companies();
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [totalRecordsNum, setTotalRecordsNum] = useState(0);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 5,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            id: { value: null, matchMode: 'contains' },
            callTitle: { value: null, matchMode: 'contains' },
            companyName: { value: null, matchMode: 'contains' },
            status: { value: null, matchMode: 'contains' }
        }
    });
    const statuses = ['SOCCORSO', 'APPROVED', 'REJECTED', 'EVALUATION', 'SUBMIT'];

    const getPaginationQuery = useCallback(() => getQueryParamsForPaginatedEndpoint(lazyState, statuses, 'id'), [lazyState]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    };

    const onFilter = useCallback((event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    }, [lazyState]);

    useEffect(() => {
        const existingCompany = head(companies.filter(o => o.id === chosenCompanyId));

        if (existingCompany && !localAsyncRequest) {
            const paginationQuery = getPaginationQuery();

            setLocalAsyncRequest(true);
            ApplicationService.getApplicationsPaginated(paginationQuery, getApplCallback, errGetApplCallback, [
                ['companyId', chosenCompanyId],
                ['statuses', statuses] // 'NDG', 'ADMISSIBLE', 'APPOINTMENT'
            ]);
        }
    }, [chosenCompanyId, companies]);

    const getApplCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const { body, totalRecords,
                //currentPage, totalPages, pageSize
            } = resp.data;
            setTotalRecordsNum(totalRecords);
            setItems(getFormattedData(body));
        }
        setLocalAsyncRequest(false);
    }

    const errGetApplCallback = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.callEndDate = is(String, d.callEndDate) ? new Date(d.callEndDate) : (d.callEndDate ? d.callEndDate : '');
            d.modifiedDate = is(String, d.modifiedDate) ? new Date(d.modifiedDate) : (d.modifiedDate ? d.modifiedDate : '');
            d.submissionDate = is(String, d.submissionDate) ? new Date(d.submissionDate) : (d.submissionDate ? d.submissionDate : '');
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            year: 'numeric'
        });
    };

    const dateSubmissionBodyTemplate = (rowData) => {
        return formatDate(rowData.submissionDate);
    };

    const importoBodyTemplate = (rowData) => {
        return getNumberWithCurrency(rowData.amountRequested);
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    return (
        <div className="appPageSection__table">
            <DataTable
                value={items} stripedRows showGridlines
                lazy filterDisplay="menu" dataKey="id" paginator
                first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecordsNum} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={localAsyncRequest}
                emptyMessage={translationStrings.emptyMessage}>
                <Column field="callTitle" header={__('Bando', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Anno', 'gepafin')} dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateSubmissionBodyTemplate}/>
                <Column field="amountRequested"
                        header={__('Importo finanziato', 'gepafin')}
                        style={{ minWidth: '7rem' }}
                        body={importoBodyTemplate}/>
                <Column field="status" header={__('Stato progetto', 'gepafin')}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default BeneficiarioUltimeDomandeTable;
